/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Michael Zuercher officially launches his second software platform today, and if early signs are correct, he might be on his way to a second startup success story."), "\n", React.createElement(_components.p, null, "Zuercher's first foray into entrepreneurship led to a national leader in the public safety software vertical – now CentralSquare Technologies."), "\n", React.createElement(_components.p, null, "It also set the stage for Prismatic, his newest venture, which is designed to solve a technology problem that plagued him in his first company."), "\n", React.createElement(_components.p, null, "Read the full story on ", React.createElement(_components.a, {
    href: "https://www.siouxfalls.business/startup-founders-second-act-launches-to-encouraging-early-results/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "SiouxFalls.Business"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
